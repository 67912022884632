.product-properties {

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;

		li {
			font-size: 18px;
			font-family: $fm;
			width: 45%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 25px 0;
			border-bottom: 1px solid $gray-300;

			b, strong {
				font-weight: 400;
				color: $gray-600;
			}
		}
	}


	@include media-breakpoint-down(md) {
		ul {
			li {
				width: 100%;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
			}
		}
	}

	@include media-breakpoint-only(md) {
		ul {
			li {
				width: 100%;
			}
		}
	}
}