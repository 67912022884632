.foot-menu {
	$className: foot-menu;

	display: flex;

	&__item {
		&-link {
			display: block;
			font-size: 20px;
			font-family: $fm;
			line-height: 1.2;
		}

		&--four {
			width: 70%;

			.#{$className}__children {
				flex-direction: row;
				flex-wrap: wrap;
				margin-right: -15px;
				margin-left: -15px;

				&-link {
					width: 50%;
					padding-right: 15px;
					padding-left: 15px;
				}
			}
		}
	}

	&__children {
		display: flex;
		flex-direction: column;
		margin-top: 30px;

		&-link {
			font-size: 16px;
			font-family: $fr;
			line-height: 1.4;
			color: $gray-600;
			padding: 8px 0;

			.foot-menu-four & {
				width: 25%;
				padding-left: 10px;
				padding-right: 10px;
			}
		}

		.foot-menu-four & {
			flex-direction: row;
			flex-wrap: wrap;
			margin-left: -10px;
			padding-left: -10px;
		}
	}


	@include media-breakpoint-down(md) {
		display: block;

		&__item {
			margin-bottom: 30px;
			text-align: center;
		}
	}
}
