body {
}


.container-fluid {
	padding: 0 5vw;

	@include media-breakpoint-only(md) {
		padding: 0 3vw;
	}
}

.container {
	padding-left: 1rem * (0.25 * 12);
	padding-right: 1rem * (0.25 * 12);

	@include media-breakpoint-down(xl) {
		padding: 0;
		max-width: inherit;
	}
}

