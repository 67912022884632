.foot-bulletin {
	position: relative;
	padding: 70px 0;
	background-color: $light-blue-grey;

	&:before {
		content: '';
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 45%;
		height: 90%;
		background-image: url('#{$img-path}footer-bg.png');
		background-repeat: no-repeat;
		background-position: right top;
		background-size: cover;
	}

	&__title {
		font-size: 28px;
		line-height: 1;
		font-family: $fm;
		color: $primary;
	}

	&__description {
		font-size: 18px;
		font-family: $fr;
		color: $gray-600;
		margin-top: 10px;
	}

	&__form {
		position: relative;

		.btn {
			position: absolute;
			top: 8px;
			right: 8px;
			bottom: 8px;

			i {
				transition: all ease 0.3s;
			}

			&:hover {
				background: $primary;

				i {
					@include wobble(infinite);
				}
			}
		}

		.form-check-input {
			background-color: #fff;

			&:checked {
				background-color: $form-check-input-checked-bg-color;
			}
		}
	}

	@include media-breakpoint-down(lg) {
		padding: 30px 0;

		&:before {
			display: none;
		}
	}

	@include media-breakpoint-only(lg) {
		&:before {
			width: 40%;
			height: 60%;
			left: -70px;
			top: 0;
		}

	}
}
