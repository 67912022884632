.site-head {
	position: relative;
	z-index: 10;

	&__inner {
		height: $header-height;
		position: relative;
		z-index: 10;

		@include media-breakpoint-down(md) {
			height: $header-height-sm;
		}

		@include media-breakpoint-only(lg) {
			height: $header-height-lg;
		}

		@include media-breakpoint-only(md) {
			height: $header-height-lg;
		}
	}
}

.head-block {
	&--left {
		@include media-breakpoint-down(md) {
			height: $header-height-sm;
		}

		@include media-breakpoint-only(lg) {
			height: $header-height-lg;
		}

		@include media-breakpoint-only(md) {
			height: $header-height-lg;
		}
	}
}

.site-foot {
	&__backdrop {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $modal-backdrop-bg;
		opacity: $modal-backdrop-opacity;
		display: none;
	}
}
