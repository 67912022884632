.page-sticky-bar {
	position: fixed;
	z-index: 5;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	padding: 30px;
	transform: translate(0, 100%);
	transition: $transition-base;

	&__inner {
		background: #fff;
		padding: 30px;
		box-shadow: $box-shadow-lg;
	}

	&__border {
		border-right: 1px solid $gray-200;
	}

	&__title {
		font-size: 26px;
		font-family: $fm;
	}

	&__description {
		font-size: 15px;
		font-family: $fm;
	}

	&__btn-group {
	}

	&__btn {
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 210px;

		i {
			margin-right: 15px;
		}
	}


	&.is-active {
		transform: translate(0, 0);
	}


	@include media-breakpoint-down(lg) {
		padding: 0;

		&__title {
			display: none;
		}

		&__description {
			display: none;
		}

		&__inner {
			margin: 0 ($grid-gutter-width) * -1;
			padding: 10px 5px;
		}

		&__btn {
			width: 50%;
			min-width: inherit;

			i {
				font-size: 20px;
				margin: 0;
			}
		}
	}

	@include media-breakpoint-only(md) {
		&__btn {
			i {
				margin-right: 20px;
			}
		}
	}

	@include media-breakpoint-only(lg) {
		&__title {
			border-right: 0;
			border-bottom: 1px solid $gray-200;
			padding-bottom: 10px;
			margin-bottom: 15px;
		}
	}
}