$category-form-padding: 40px;

.category-form {

	position: relative;
	z-index: 1;
	padding: $category-form-padding - 20;
	max-width: 500px;
	height: 100%;

	&__inner {
		background: #fff;
		padding: $category-form-padding;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	&__head {
	}

	&__body {
		margin: ($category-form-padding - 10) 0;
	}

	&__foot {
		margin-top: auto;
	}

	&__title {
		font-size: 30px;
		line-height: 1;
		font-family: $fm;
	}

	&__description {
		font-size: 16px;
		line-height: 1.4;
		font-family: $fr;
		color: $gray-600;
		margin-top: 10px;
	}

	@include media-breakpoint-down(lg) {
		position: relative;
		padding: 0;
		max-width: inherit;

		&__inner {
			padding: 24px;
			border: 1px solid $gray-200;
		}
	}

	@include media-breakpoint-only(md) {
		padding: 40px;
		margin-top: (40px * 4) * -1;

		&__inner {
		}
	}

	@include media-breakpoint-only(lg) {
		max-width: 400px;

	}

}

.category-form-cta {
	background: $teal;
	color: #fff;
	display: flex;
	flex-direction: column;
	margin: 0 ($category-form-padding * -1);
	margin-bottom: ($category-form-padding * -1);
	padding: $category-form-padding;
	transition: $transition-base;

	&__label {
		font-size: 22px;
		font-family: $fm;
		line-height: 1;
	}

	&__value {
		font-size: 32px;
		font-family: $fm;
		line-height: 1;
		margin-top: 10px;
	}

	&:hover {
		background: $teal-600;
		color: #fff;
	}

	@include media-breakpoint-down(lg) {
		margin: 0 -30px;
		margin-bottom: -30px;
		padding: 30px;

		&__label {
			font-size: 18px;
		}

		&__value {
			font-size: 32px;
		}
	}
}
