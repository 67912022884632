.head-dropdown-menu {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding-top: $header-height + 30;

	display: none;
	z-index: -1;
	opacity: 0;
	transform: translate(0, 30px);
	transition: all ease 0.3s;

	&__inner {
		background: $gray-100;
	}

	&.active {
		z-index: 9;
		display: block;

		&.show {
			opacity: 1;
			transform: translate(0, 0);
		}
	}


	@include media-breakpoint-down(xl) {
		position: fixed;
		height: 100%;
		background: $gray-100;
		padding: 30px 0;
		overflow-y: scroll;

		&.active {
			z-index: 10;
		}
	}

}
