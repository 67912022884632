.box-group {
	$className: box-group;

	position: relative;
	margin: $box-group-padding 0;

	&__head {
		margin-bottom: $box-group-padding / 2;
		position: relative;

		&:before {
			content: attr(data-title);
			position: absolute;
			z-index: -1;
			top: 50%;
			left: 50%;
			transform: translate(0, -50%);
			font-size: 150px;
			line-height: 1;
			font-family: $fb;
			color: $gray-100;
			white-space: nowrap;
		}
	}

	&__body {
	}

	&__foot {
		margin-top: $box-group-padding / 4;
	}

	&__title {
		font-size: 40px;
		line-height: 1;
		font-family: $fm;

		&--big {
			font-size: 50px;
		}
	}

	&__sub-title {
		font-size: 22px;
		line-height: 1.4;
		font-family: $fr;
		margin-top: 20px;
		color: $gray-600;

		b {
			font-weight: 700;
		}
	}

	&__description {
		margin-top: 20px;

		p {
			font-size: 17px;
			font-family: $fr;
			color: $gray-600;
			margin-bottom: 0;
		}
	}

	&__btn {
		margin-top: 60px;
		min-width: 210px;
	}


	@include media-breakpoint-down(md) {
		&__title {
			font-size: 30px;
		}
	}

	@include media-breakpoint-down(md) {
		margin: $box-group-padding-sm 0;

		&__head {
			margin-bottom: $box-group-padding-sm / 2;
		}
	}
}
