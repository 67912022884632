.contact-map {
	width: 100%;
	height: 70vh;

	@include media-breakpoint-only(md) {
		height: 450px;
	}

	@include media-breakpoint-down(md) {
		height: 300px;
	}

}

.gm-style-iw {
	max-width: 300px $i;
	text-align: center $i;
	padding: 30px $i;
	border-radius: 0 $i;
	box-shadow: 0 0 10px 0 rgba(#000, 0.05) $i;
}

.gm-ui-hover-effect {
	// display: none $i;
}

.map-iw-head {
	font-weight: 700;
	font-size: 22px;
	text-align: center;
	margin-bottom: 15px;
}

.map-iw-body {
	font-weight: 400;
	font-size: 18px;
	line-height: 1.5;
}
