.foot-social {

	&__list {
		display: flex;
		margin: 0 -5px;
	}

	&__label {
		font-size: 15px;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: 15px;
	}

	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		transition: ease 0.3s;
		color: $primary;

		&:hover {
			background: $primary;
			color: #fff;
		}
	}

	&__icon {
		font-size: 20px;
	}
}
