.page-head {

	$className: page-head;

	&__title {
		font-size: 50px;
		line-height: 1;
		font-family: $fm;
		color: $primary;
		margin: 0;
	}

	&__sub-title {
		font-size: 22px;
		line-height: 1.4;
		font-family: $fm;
		color: $gray-600;
		margin: 0;
		margin-top: 40px;
	}

	&__image-box {
		position: relative;
	}

	&__image {
		@include centerImage(100%, 100%);
	}

	&__icon {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translate(-50%, -50%) scale(1.1);
		background-image: url(#{$img-path}icon.svg);
		width: 70px;
		height: 73px;
	}


	@include media-breakpoint-down(lg) {
		&__title {
			font-size: 40px;
		}

		&__sub-title {
			font-size: 18px;
		}
	}


	&--bg {
		.#{$className}__inner {
			background: $gray-100;
			padding: 50px 0;
		}
	}
}
