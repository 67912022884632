.head-menu {
    display: flex;
    align-items: center;

    &__item {
        padding: 0 8px;

        &-link {
            font-size: 16px;
            font-family: $fm;
            padding: 10px 2px;
            margin: 0;
            display: flex;
            align-items: center;
            color: $primary;
            border-top: 3px solid transparent;
            border-bottom: 3px solid transparent;

            &:hover, &.active {
                text-decoration: none;
                border-bottom-color: $blue-2;
            }
        }
    }

    &__children {
        display: none;

        @include media-breakpoint-down(xl) {
            display: flex;
            flex-direction: column;

            &-link {
                display: block;
                padding: 5px 0;
            }

        }
    }

    @include media-breakpoint-down(xl) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &__item {
            width: 100%;
            padding: 0;

            &-link {
                margin: 0;
                font-size: 20px;
                color: $body-color;
                padding: 10px 0;
                height: auto;

                &:hover, &.is-active {
                }
            }
        }
    }
}
