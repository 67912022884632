.site-foot {
    margin-top: 60px;

    &__backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $modal-backdrop-bg;
        opacity: $modal-backdrop-opacity;
        display: none;
    }

    &__fixed-btn {
        position: fixed;
        z-index: 1;
        bottom: 50px;
        right: 50px;

        img {
            width: 50px;
        }

        .ripple {
            position: absolute;
            width: 120px;
            height: 120px;
            z-index: 0;
            left: 50%;
            top: 50%;
            opacity: 0;
            margin: -60px 0 0 -60px;
            border-radius: 100px;
            animation: ripple 1.8s infinite;
            background: $whatsapp-color;

            &:nth-child(2) {
                animation-delay: .3s;
                -webkit-animation-delay: .3s
            }

            &:nth-child(3) {
                animation-delay: .6s;
                -webkit-animation-delay: .6s
            }
        }

        @include media-breakpoint-down(lg) {
            .urun-category &, .urun-page & {
                bottom: 100px;
            }
        }
    }
}

.foot-block {

    &--top {
        padding: 70px 0;
    }

    &--bottom {
        padding: 30px 0;
    }

    @include media-breakpoint-down(xl) {
        .container {
            padding-left: 5vw;
            padding-right: 5vw;
        }
    }

    @include media-breakpoint-down(lg) {
        &--top {
            padding: 30px 0;
        }
    }

    @include media-breakpoint-down(md) {
        .container {
            padding-right: 24px;
            padding-left: 24px;
        }
    }

}
