.blog-list {

	$className: blog-list;

	&__item {
		display: block;

		span {
			display: block;
		}

		&:hover {
			.#{$className}__image {
				transform: scale(1.1);
			}
		}
	}

	&__image-box {
		overflow: hidden;
	}

	&__image {
		max-width: 100%;
		transition: $transition-base;
	}

	&__title {
		font-size: 22px;
		line-height: 1.2;
		font-family: $fm;
		@include shortText(22px, 1.2, 2);
	}

	&__more-btn {
		margin-top: 20px;

		i {
			font-size: 20px;
		}
	}

}
