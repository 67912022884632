.home-solution {

	$className: home-solution;

	$box-height: 680px;

	&__item {
		position: relative;
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		min-height: $box-height;

		&--big {
			padding: 60px;

			.#{$className}__image {
				top: auto;
				bottom: 2.5rem;
				right: 2.5rem;
				transform: none;
				position: absolute;
			}

			.#{$className}__text-box {
				margin-bottom: auto;
				width: 40%;
			}

			.#{$className}__description {
				@include shortText(18px, 1.3, 4);
			}
		}

		&--small {
			padding: 40px;
			min-height: (rem($box-height) / 2) - ($grid-gutter-width / 2);

			.#{$className}__image {
				max-height: 330px;
			}
		}
	}

	&__icon {
		width: 70px;
		margin-bottom: 30px;
	}

	&__title {
		font-size: 28px;
		line-height: 1.3;
		font-family: $fm;
	}

	&__description {
		font-size: 18px;
		line-height: 1.3;
		font-family: $fr;
		opacity: 0.6;
		margin-top: 15px;
		@include shortText(18px, 1.3, 2);
	}

	&__btn {
		margin-top: 25px;
	}

	&__image {
		margin-left: 60px;
	}

	@include media-breakpoint-down(md) {
		&__item {
			padding: 24px $i;
			min-height: inherit;
		}

		&__image {
			display: none;
		}

		&__text-box {
			width: 100% $i;
		}
	}

}
