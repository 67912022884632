.category-promo {
	position: relative;

	&__image {
		position: absolute;
		top: 0;
		left: 0;
		@include centerImage(100%, 100%);
	}

	@include media-breakpoint-down(lg) {
		&__image {
			height: auto;
			position: relative;
		}
	}

}
