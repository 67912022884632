.form-control {
	box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
}

.form-check-label {

	a {
		color: $body-color;
		margin-right: 5px;
		text-decoration: underline;
	}
}
