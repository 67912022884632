.page-breadcrumb {
	padding: 30px 0;

	&__list {
		list-style-type: none;
		padding: 0;
		margin: 0;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	&__item {
		font-size: 14px;
		font-family: $fr;
		line-height: 1.2;
		color: $gray-500;
		display: flex;
		align-items: center;
		white-space: nowrap;

		&:after {
			@include fi($fi-angle-right);
			font-size: 8px;
			display: inline-block;
			margin: 0 8px;
			margin-top: 2px;
		}

		&:last-child {
			color: $body-color;

			&:after {
				display: none;
			}
		}
	}

	&__link {
		color: $gray-500;
		padding: 3px 0;
	}

	&__text {

	}

	@include media-breakpoint-down(xl) {
		padding: 20px 0;

		&__list {
			flex-wrap: nowrap;
			overflow-x: scroll;
			white-space: nowrap;
		}
	}
}
