.home-promo {
	position: relative;
	width: 100%;
	height: 100vh;
	margin-top: ($header-height + 30) * -1;

	&__item {
		width: 100%;
		height: 100vh;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		&.is-selected {
			z-index: 1;
		}
	}

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center;

		img {
			position: absolute;
			top: 0;
			left: 0;
			@include centerImage(100%, 100%);
		}
	}

	&__text-box {
		position: absolute;
		bottom: 5vw;
		left: 5vw;
		background: #fff;
		max-width: 540px;
		padding: 40px;
		display: flex;
		flex-direction: column;

		transform: translate(0, 100%);
		opacity: 0;
		transition: all ease 0.3s;

		.is-selected & {
			transform: translate(0, 0);
			opacity: 1;
		}
	}

	&__title {
		font-size: 52px;
		line-height: 1;
		font-family: $fm;
	}

	&__btn {
	}


	@include media-breakpoint-down(md) {
		margin-top: ($header-height-sm) * -1;

		&__text-box {
			bottom: 24px;
			left: 24px;
			width: calc(100% - 24px * 2);
		}

		&__title {
			font-size: 40px;
		}
	}

	@include media-breakpoint-only(lg) {
		margin-top: ($header-height-lg) * -1;
	}

}
