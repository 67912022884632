.product-dropdown-menu {
	overflow: hidden;

	&__head {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		border-bottom: 1px solid $gray-300;
		margin: 0 ($grid-gutter-width / 2) * -1;
		margin-bottom: 40px;
	}

	&__head-item {
		margin: 0 $grid-gutter-width / 2;
		font-size: 16px;
		line-height: 1;
		font-family: $fm;
		color: $gray-600;
		border-top: 2px solid transparent;
		border-bottom: 2px solid transparent;
		padding: 30px 0;

		&.active {
			color: $blue-2;
			border-bottom-color: $blue-2;
		}
	}


	@include media-breakpoint-down(xl) {
		&__head {
			flex-wrap: nowrap;
			overflow-x: scroll;
			margin: 0;
			margin-bottom: 30px;
		}

		&__head-item {
			padding: 25px 10px;
		}
	}
}
