.sidebar-menu {

	&__item {
		font-size: 15px;
		font-weight: 500;
		color: $gray-600;
		display: block;
		padding: 10px 0;

		&.is-active {
			color: $primary;
		}

		&:hover {
			color: $primary;
		}
	}

}
