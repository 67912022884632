.category-detail {

    &-banner {
        position: relative;

        &__image {
            width: 100%;
        }

        &__text {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 40px;
            max-width: 600px + (40 * 2);

            .page-article {
                background: #fff;
                padding: 40px;
            }
        }


        @include media-breakpoint-down(lg) {
            &__text {
                position: relative;
                margin-top: 0;
                padding: 0;
                max-width: inherit;

                .page-article {
                    padding: 24px;
                    border: 1px solid $gray-200;
                }
            }
        }

        @include media-breakpoint-only(lg) {
            &__text {
                position: relative;
                margin-top: -220px;
                max-width: inherit;

                .page-article {
                    border: 1px solid $gray-200;
                }
            }
        }
    }

    &-gallery {
        position: relative;

        &__list {
            &:before, &:after {
                display: none;
                content: '';
                position: absolute;
                z-index: 1;
                top: 0;
                width: 30%;
                height: 100%;
                pointer-events: none;
            }

            &:before {
                left: -5vw;
                @include gradient-x(#fff, transparent);
            }

            &:after {
                right: -5vw;
                @include gradient-x(transparent, #fff);
            }
        }

        &__flickity {
            .btn {
                &-prev {
                }

                &-next {
                }
            }
        }

        @include media-breakpoint-down(md) {
            &__list {
                &:before, &:after {
                    width: 50px;
                }
            }
        }
    }

    &-infobox {
        margin: 0 ($grid-gutter-width / 2) * -1;

        .col {
            width: (100% / 5);
        }

        &__item {
            background: $gray-100;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 320px;
        }

        &__icon {
            margin-bottom: 30px;
            text-align: center;
        }

        &__title {
            font-size: 20px;
            line-height: 1;
            font-family: $fm;
        }


        @include media-breakpoint-down(md) {
            .col {
                width: (100% / 2);
            }

            &__item {
                height: 190px;
                padding: 20px;
            }

            &__title {
                font-size: 18px;
            }

            &__icon {
                img {
                    max-width: 60%;
                }
            }
        }

        @include media-breakpoint-only(md) {
            .col {
                width: (100% / 3);
            }

            &__item {
                height: 220px;
            }
        }

        @include media-breakpoint-only(lg) {
            &__item {
                height: 220px;
            }
        }
    }

    &-video-btn {
        display: block;
        position: relative;

        &__icon {
            width: 100px;
            height: 100px;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            i {
                font-size: 26px;
                padding-left: 2px;
                color: $cyan;
            }
        }

    }
}

