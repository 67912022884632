.foot-copyright {
	&__image {
		height: 35px;
	}

	&__text {
		font-size: 16px;
		line-height: 1.2;
		font-family: $fr;
		color: $gray-600;
	}


	@include media-breakpoint-down(md) {
	}
}

