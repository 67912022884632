.home-blog {
	padding-bottom: 90px;

	&__text-box {
		position: relative;
		z-index: 1;
		background: #fff;
		width: 440px;
		height: 440px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 70px;
	}

	&__image-box {
		position: absolute;
		top: 0;
		left: -90px;
		transform: translate(100%, 90px);
	}

	&__image {
		@include centerImage(440px, 440px);
	}

	&__title {
		font-size: 22px;
		line-height: 1.4;
		font-family: $fb;
		@include shortText(22px, 1.4, 2);
	}

	&__date {
		font-size: 18px;
		font-family: $fr;
		color: $cyan;
		margin-top: 5px;
	}

	&__description {
		font-size: 18px;
		line-height: 1.4;
		font-family: $fr;
		color: $gray-600;
		margin-top: 20px;
		@include shortText(18px, 1.4, 4);
	}

	&__btn {
		margin-top: 30px;
	}


	@include media-breakpoint-down(md) {
		padding-bottom: 0;

		&__text-box {
			width: auto;
			height: auto;
			margin: 0;
			padding: 24px;
		}

		&__image-box {
			position: relative;
			right: auto;
			left: auto;
			transform: translate(0, 0);
		}

		&__image {
			width: 100%;
			height: auto;
		}
	}

	@include media-breakpoint-only(md) {
		padding-bottom: 220px;

		&__text-box {
			margin: 0;
		}

		&__image-box {
			top: 90px;
		}
	}
}
