.home-category {

	&__item {
		position: relative;
		display: block;
		height: 100%;
	}

	&__image {
		max-width: 100%;
		@include centerImage(100%, 100%);

		&--catalog {
			position: absolute;
			bottom: 0;
			right: 0;

			@include media-breakpoint-down(md) {
				position: relative;
			}
		}
	}

	&__text-box {
		padding: 40px;
		height: 100%;
		display: flex;
		flex-direction: column;

		&--absolute {
			position: absolute;
			max-width: 380px;
			height: auto;
		}

		&--left {
			left: 40px;
		}

		&--bottom {
			bottom: 40px;
		}
	}

	&__title {
		font-size: 30px;
		line-height: 1;
		font-family: $fm;
	}

	&__description {
		font-size: 16px;
		line-height: 1.6;
		font-family: $fr;
		color: $gray-600;
		margin-top: 20px;
		margin-bottom: 30px;

		&--3 {
			@include shortText(16px, 1.6, 3);
		}
	}

	&__btn {
		margin-top: auto;
		display: flex $i;
		align-items: center;


		i {
			color: $info;
			margin-right: 20px;
		}

		span {
			font-size: 18px;
			font-family: $fr;
		}
	}


	@include media-breakpoint-down(md) {

		&__text-box {
			&--absolute {
				position: relative;
				height: auto;
				bottom: auto;
				left: auto;
				padding: 25px;
				border: 1px solid $gray-200;
			}
		}

		&__image {
			height: auto;
		}

	}
}
