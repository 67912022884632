.gallery {
	$className: gallery;

	display: flex;
	flex-wrap: wrap;
	margin: -4px;

	&__item {
		border: 1px solid $gray-200;
		padding: 10px;
		position: relative;

		&:hover {
			.#{$className}__image {
				opacity: 0.8;
			}
		}

		&--video {
			position: relative;

			&:before {
				@include fi($fi-angle-right);
				position: absolute;
				z-index: 1;
				top: 50%;
				left: 50%;
				color: #fff;
				border: 2px solid #fff;
				font-size: 20px;
				width: 70px;
				height: 70px;
				border-radius: 70px;
				margin-left: (70px / 2) * -1;
				margin-top: (70px / 2) * -1;
				display: flex;
				align-items: center;
				justify-content: center;
				pointer-events: none;
				transition: all ease 0.3s;
				transform-origin: center;
			}

			&:hover {
				&:before {
					transform: scale(1.2);
					background: #fff;
					color: $body-color;
				}
			}
		}
	}

	&__link {
		display: block;
	}

	&__image {
		transition: all ease 0.3s;
	}

	&__count {
		width: 100%;
		height: 100%;
		background: $primary;
		color: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 60px;
		line-height: 1;
		font-family: $fb;
	}

	@include media-breakpoint-down(md) {
		&__item {
		}

		&__image {
			width: 100%;
			height: auto;
		}
	}

	@include media-breakpoint-only(md) {
		&__item {
		}
	}

}
