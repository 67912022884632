.category-list {

	$className: category-list;

	&__item {
		display: block;
		position: relative;

		span {
			display: block;
		}

		&:hover {
			.#{$className}__image {
				transform: scale(1.1);
			}
		}
	}

	&__image-box {
		overflow: hidden;
	}

	&__image {
		max-width: 100%;
		transition: $transition-base;
	}

	&__title {
		font-size: 20px;
		line-height: 1;
		font-family: $fm;
	}

}
