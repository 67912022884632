.head-contact {
	$className: head-contact;

	&:hover {
	}

	&__label {
		font-size: 14px;
		line-height: 1;
	}

	&__value {
		font-size: 20px;
		line-height: 1;
		font-family: $fm;
	}

	&__icon {
	}

}
