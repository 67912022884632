.page-sidebar {


	&__fixed-btn {
		.btn {
			transition: $transition-base;

			&.is-active {
				transform: rotate(180deg);
			}
		}


		@include media-breakpoint-down(md) {
			position: fixed;
			bottom: 0;
			right: 0;
			z-index: 2;
			width: 100%;
			padding: 10px;
		}

		@include media-breakpoint-between(md, xl) {
			width: 30%;
		}
	}

	&--sticky {
		position: sticky;
		top: 30px;

		@include media-breakpoint-down(md) {
			&.no-fixed {
				position: relative;
				top: 0;
			}
		}

		@include media-breakpoint-only(lg) {
			top: $header-height-lg + 30;
		}

		@include media-breakpoint-only(md) {
			top: $header-height-md + 30;
		}

	}


}
