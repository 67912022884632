.contact-address {


	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			display: flex;
			flex-direction: column;
			padding: 15px 0;

			font-size: 22px;
			font-family: $fm;
			line-height: 1.2;
			color: $primary;

			b {
				display: block;
				font-size: 18px;
				font-family: $fm;
				font-weight: 400;
				color: rgba($primary, 0.4);
				margin-bottom: 5px;
			}

			a {
			}
		}
	}


	@include media-breakpoint-down(md) {
	}

}
