.contact-form {

	&__head {
	}

	&__body {
	}

	&__title {
		font-size: 28px;
		font-family: $fm;
		line-height: 1.2;
	}

	&__description {
		font-size: 17px;
		font-family: $fr;
		line-height: 1.6;
		margin-top: 10px;
		color: $gray-600;
	}


	&--border {
		border: 5px solid $gray-100;
	}

	&--bg {
		border: 5px solid $gray-100;
		background: $gray-100;
	}
}
