.head-language-dropdown {
	display: flex;
	border-left: 1px solid rgba($black, 0.1);

	&__btn {
		border-color: transparent;
		font-family: $fm;
		padding: 5px 5px;

		.show & {
			border: 1px solid $dropdown-border-color;
			box-shadow: none;
		}

		&:after {
			@include fi($fi-angle-down);
			font-size: 11px;
			margin-left: 10px;
		}
	}

	&__dropdown {
		min-width: inherit;
		width: 100%;
		margin-top: -2px;
	}

	&__item {
		font-weight: 700;
		font-size: 15px;

		&.is-active {
		}

		&:hover {
		}
	}

	@include media-breakpoint-down(xl) {
		border-left: 0;
		padding-left: 0;
		margin-left: 0;

		&__dropdown {
			width: auto;
		}
	}
}
