.goto-page {
	position: absolute;
	z-index: 5;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	bottom: 0;
	right: 0;
}

.text-truncate-5 {
	@include shortText(18px, 1.8, 5);
}

/* Opacity */
@for $i from 0 through 9 {
	.opacity-#{$i} {
		opacity: #{$i / 10} !important;
	}
}

/* Border Radius */
@for $i from 0 through 20 {
	.border-radius-#{$i} {
		border-radius: #{$i}px !important;
	}
}

/* Position: top, bottom, left, right */
@each $prop, $abbrev in (top: t, bottom: b, right: r, left: l) {
	@each $size, $length in $spacers {
		.#{$abbrev}-#{$size} {
			#{$prop}: $length !important;
		}
		.#{$abbrev}-n#{$size} {
			#{$prop}: -$length !important;
		}
	}
}

/* Font Size & Line Height */
@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
		@for $i from 8 through 64 {
			.fz#{$infix}-#{$i} {
				font-size: #{$i}px !important;
			}

			.lh#{$infix}-#{$i} {
				line-height: #{$i}px !important;
			}
		}
	}
}

@-webkit-keyframes ripple {
    0% {
        opacity: 1;
        -webkit-transform: scale(0);
        transform: scale(0)
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes ripple {
    0% {
        opacity: 1;
        -webkit-transform: scale(0);
        transform: scale(0)
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}
