.faq {
	&__item {
		background: none;
		border-bottom: 1px solid $gray-300;

		&:last-child {
			border: none;
		}
	}

	&__head {
	}

	&__body {
		&-inner {
			padding: 0;
		}
	}

	&__btn {
		font-size: 20px;
		font-family: $fm;
		background: none;
		padding: 30px 0;
		display: flex;
		justify-content: space-between;

		&:after {
			background: none;
			width: auto;
			height: auto;
			font-size: 18px;
			line-height: 20px;
			@include fi($fi-plus);
			color: $primary;
			padding: 8px;
			margin: -4px 0;
			margin-left: auto;
			border-radius: 50px;
		}

		&:not(.collapsed) {
		}
	}

	@include media-breakpoint-down(lg) {
		&__btn {
			&:after {
				margin-left: 30px;
			}
		}
	}


	@include media-breakpoint-down(md) {
		&__btn {
			font-size: 18px;
			line-height: 1.4;
		}
	}
}
