.feature-blog-post {

	&__item {
		border: 5px solid $gray-100;
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 440px;
		padding: 0 50px;
		width: 380px;
		margin-right: 20px;
	}

	&__title {
		font-family: $fb;
		font-size: 22px;
	}

	&__date {
		font-family: $fr;
		font-size: 18px;
		color: $cyan;
		margin-top: 15px;
	}

	&__description {
		font-family: $fr;
		font-size: 18px;
		color: $gray-600;
		margin-top: 25px;
	}

	&__btn {
		margin-top: 35px;
	}

	@include media-breakpoint-down(sm) {
		&__item {
			width: 90%;
			min-height: auto;
			padding: 30px;
		}
	}

}
