.sidebar-widget {
	position: relative;
	padding: 30px;
	border-radius: $border-radius-lg;
	border: 1px solid $border-color;
	box-shadow: $box-shadow-lg;
	background: #fff;


	&__head {
		border-bottom: 1px solid $border-color;
		margin-bottom: 20px;
		padding-bottom: 20px;
	}

	&__title {
		font-size: 18px;
		line-height: 1.2;
		font-family: $fm;
	}

	&__body {
		p {
			font-size: 15px;
			font-family: $fr;
			line-height: 1.4;
			color: $gray-500;
			margin-bottom: 0;
		}
	}


	&--fixed {
		@include media-breakpoint-down(md) {
			position: fixed;
			z-index: 1;
			bottom: 0;
			left: 0;
			width: 100%;
			@include border-bottom-radius(0);
			border-left: none;
			border-right: none;
			border-bottom: none;
			transform: translate(0, 100%);
			opacity: 0;
			transition: $transition-base;
			padding-bottom: 100px;

			&.is-active {
				transform: translate(0,0);
				opacity: 1;
			}
		}
	}
}
